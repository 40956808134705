import React, { useEffect } from 'react';

function MobileLayout({ layoutItems }) {
  // Helper function to find an item by its ID
  const findItemById = (id) => layoutItems.find((item) => item.id === id);

  // Recursive function to calculate spacing
  const calculateTotalSpacing = (item, type) => {
    const isActive = item[`${type}Active`];
    const itemId = item[`${type}ItemId`];
    const value = item[type];

    if (!isActive) return null;
    if (!itemId) return Math.abs(value) || 0;

    const parentItem = findItemById(itemId);
    return parentItem ? value + calculateTotalSpacing(parentItem, type) : 0;
  };

  const renderItem = (item) => {
    const totalLeftSpacing = calculateTotalSpacing(item, 'left');
    const totalRightSpacing = calculateTotalSpacing(item, 'right');
    const totalTopSpacing = calculateTotalSpacing(item, 'top');
    const totalBottomSpacing = calculateTotalSpacing(item, 'bottom');

    const style = {
      position: 'absolute',
      top: totalTopSpacing !== null ? `${totalTopSpacing}px` : 'auto',
      left: totalLeftSpacing !== null ? `${totalLeftSpacing}px` : 'auto',
      right: totalRightSpacing !== null ? `${totalRightSpacing}px` : 'auto',
      bottom: totalBottomSpacing !== null ? `${totalBottomSpacing}px` : 'auto',
      width: item.width ? `${item.width}px` : 'auto',
      height: item.height ? `${item.height}px` : 'auto',
      backgroundColor: `#${item.backgroundColor}`,
      color: `#${item.foregroundColor}`,
      fontSize: `${item.fontSize}px`,
      zIndex: item.zIndex,
      borderWidth: item.borderWidth ? `${item.borderWidth}px` : '0',
      borderColor: `#${item.borderColor}`,
      borderRadius: item.cornerRadius,
    };

    switch (item.type) {
      case 0: // Symbol
        return <div key={item.id} style={style}><p>Symbol</p></div>;

      case 1: // Text
        return <div key={item.id} style={style}><p>{item.text}</p></div>;

      case 2: // Image
        return (
          <div key={item.id} style={style}>
            <img src={item.source} alt="" />
          </div>
        );

      case 3: // Video
        return (
          <video key={item.id} style={style} controls>
            <source src={item.source} />
          </video>
        );

      case 7: // Apple Music
        return (
          <div key={item.id} style={style}>
            <img
              src={item.appleMusicSongArtwork}
              alt="Song Artwork"
              style={{ width: '60px', height: '60px', objectFit: 'cover' }}
            />
            <div>
              <p>{item.appleMusicSongTitle}</p>
              <p>{item.appleMusicSongArtistName}</p>
              <p>{item.appleMusicSongAlbumName}</p>
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className="flex" style={{ position: 'relative' }}>
      {layoutItems.map((item) => renderItem(item))}
    </div>
  );
}

export default MobileLayout;
