import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import MobileLayout from '../Components/Layout/MobileLayout';
import TabletLayout from '../Components/Layout/TabletLayout';
import LaptopLayout from '../Components/Layout/LaptopLayout';
import DesktopLayout from '../Components/Layout/DesktopLayout';

function Layout() {
  const location = useLocation();
  const [layout, setLayout] = useState({});
  const [layoutItems, setLayoutItems] = useState([]);

  let size = 'Mobile';

  if (window.innerWidth < 500) {
    size = 'Mobile';
  } else if (window.innerWidth >= 500 && window.innerWidth < 1024) {
    size = 'Tablet';
  } else if (window.innerWidth >= 1024 && window.innerWidth < 1440) {
    size = 'Laptop';
  } else if (window.innerWidth >= 1440) {
    size = 'Desktop';
  }
  const [currentSize, setCurrentSize] = useState(size);

  useEffect(() => {
    const segments = location.pathname.split('/');
    const last = segments.pop() || segments.pop(); // Handle potential trailing slash
    // const baseUrl = "http://localhost:3000"; // Local
    // const baseUrl = "htp://192.168.1.206:3000"; // Accessing via device
    const baseUrl = "https://test.curatelife.app"; // Test
    // const baseUrl = "https://dev.curatelife.app"; // Dev
    const layoutItemsUrl = `${baseUrl}/api/app/layoutItems/layout/${last}`;

    axios.get(layoutItemsUrl).then((res) => {
      const layout = res.data.layout;
      setLayout(layout);
      const items = res.data.layoutItems.map((item) => ({
        id: item.id,
        type: item.type,
        text: item.text,
        backgroundColor: item.backgroundColor,
        foregroundColor: item.foregroundColor,
        fontSize: item.fontSize,
        height: item.topActive && item.bottomActive ? null : item.height,
        width: item.leftActive && item.rightActive ? null : item.width,
        left: item.leftActive ? item.leftConstant : null,
        top: item.topActive ? item.topConstant : null,
        right: item.rightActive ? item.rightConstant : null,
        bottom: item.bottomActive ? item.bottomConstant : null,
        zIndex: item.z,
        source: item.source,
        leftItemId: item.leftItemId,   // Assuming you have IDs for dependencies
        rightItemId: item.rightItemId,
        topItemId: item.topItemId,
        bottomItemId: item.bottomItemId,
        leftActive: item.leftActive,
        rightActive: item.rightActive,
        topActive: item.topActive,
        bottomActive: item.bottomActive,
        borderWidth: item.borderWidth,
        borderColor: item.borderColor,
        cornerRadius: item.cornerRadius,
      }));
      setLayoutItems(items);
    });

    function handleResize() {
      // do magic for resize
      console.log('resized to: ', window.innerWidth);

      if (window.innerWidth < 500) {
        setCurrentSize('Mobile');
      } else if (window.innerWidth >= 500 && window.innerWidth < 1024) {
        setCurrentSize('Tablet');
      } else if (window.innerWidth >= 1024 && window.innerWidth < 1440) {
        setCurrentSize('Laptop');
      } else if (window.innerWidth >= 1440) {
        setCurrentSize('Desktop');
      }
    }
     
     window.addEventListener('resize', handleResize);
  }, [location.pathname]);

  return (
    <div>
      {(() => {
        switch (currentSize) {
          case 'Mobile':
            return <MobileLayout layoutItems={layoutItems} />
          case 'Tablet':
            return <p>Layout not responsive</p>
            // return <TabletLayout layoutItems={layoutItems} />
          case 'Laptop':
            // return <LaptopLayout layoutItems={layoutItems} />
            return <p>Layout not responsive</p>
          case 'Desktop':
            // return <DesktopLayout layoutItems={layoutItems} />
            return <p>Layout not responsive</p>
        }
      })()}
    </div>
  );
}

export default Layout;
