import './App.css';
import Explore from './Pages/Explore';
import Layout from './Pages/Layout';
import { Routes, Route, Router } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <div className='p-0'>
        <Routes>
          <Route path='/' element={<Explore />}></Route>
          <Route path='/layout/:id' element={<Layout />}></Route>
        </Routes>
      </div>
    </div>
  );
}

export default App;
